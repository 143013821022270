<template>
  <form-view
    :url="`/car/tests/${!dataForm.id ? 'save' : 'updateById'}`"
    :data-form="dataForm"
    v-loading="loading"
    :custom-validator="customValidator"
    :before-submit="beforeSubmit"
  >
    <el-row :gutter="25">
      <el-col :span="8">
        <el-form-item label="自编号" prop="carId">
          <el-select filterable v-model="dataForm.carId" @change="handleChangeCar">
            <el-option v-for="s in carList" :key="s.id" :label="s.number" :value="s.id"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="车牌号码" prop="licensePlate">
          <el-input disabled v-model="dataForm.licensePlate"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="车辆型号" prop="carSize">
          <el-input disabled v-model="dataForm.carSize"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="25">
      <el-col :span="6">
        <el-form-item label="检测类型" prop="testsType">
          <el-select filterable v-model="dataForm.testsType">
            <el-option v-for="s in testTypes" :key="s.dictId" :label="s.dictName" :value="s.dictId"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="检测单位" prop="testsCompany">
          <el-input v-model="dataForm.testsCompany"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="检测日期" prop="testsDate">
          <el-date-picker value-format="timestamp" v-model="dataForm.testsDate"></el-date-picker>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="截止日期" prop="testsExpireDate">
          <el-date-picker value-format="timestamp" v-model="dataForm.testsExpireDate"></el-date-picker>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row style="margin-bottom:20px;">
      <image-uploader
        @add="addImage"
        @remove="removeImage"
        :images="dataForm.images || []"
        no-title
      ></image-uploader>
    </el-row>
  </form-view>
</template>

<script>
import FormView from '@/components/templates/form-view'
import ImageUploader from '@/components/image-uploader'
import dayjs from 'dayjs'

export default {
  name: 'car-detection-update',

  components: { FormView, ImageUploader },

  data () {
    return {
      loading: false,
      dataForm: {},
      carList: [],
      testTypes: []
    }
  },

  created () {
    this.init()
  },

  methods: {
    async init () {
      const id = this.$route.query.id
      this.loading = true
      if (id) {
        const data = await this.$http({
          url: this.$http.adornUrl('/car/tests/getById'),
          method: 'post',
          data: id
        })
        if (data && data.code === 200) {
          this.dataForm = data.datas
          this.dataForm.testsType = parseInt(this.dataForm.testsType)
          this.dataForm.testsDate = dayjs(this.dataForm.testsDate).valueOf()
          this.dataForm.testsExpireDate = dayjs(this.dataForm.testsExpireDate).valueOf()
          if (this.dataForm.testsImage) {
            this.dataForm.images = this.dataForm.testsImage.split(',').map(j => ({ url: '/files' + j }))
            this.dataForm.imageList = this.dataForm.images.map(j => j.url.substring('/files'.length))
          } else {
            this.dataForm.images = []
            this.dataForm.imageList = []
          }
          this.loading = false
        }
      } else {
        this.dataForm = {
          carId: '',
          licensePlate: '',
          carSize: '',
          testsType: '',
          testsCompany: '',
          testsDate: '',
          testsExpireDate: '',
          imageList: []
        }
        this.loading = false
      }
      let data = await this.$http({
        url: this.$http.adornUrl('/car/list'),
        method: 'post',
        data: {}
      })
      this.carList = data.datas
      data = await this.$http({
        url: this.$http.adornUrl('/common/carTestsType'),
        method: 'post'
      })
      this.testTypes = data.datas
    },

    handleChangeCar (carID) {
      const target = this.carList.find(c => c.id === carID)
      this.dataForm.licensePlate = target.licensePlate
      this.dataForm.carSize = target.carSize
    },

    addImage (path) {
      this.dataForm.imageList.push(path)
    },

    removeImage (url) {
      this.dataForm.imageList.splice(this.dataForm.imageList.indexOf(url), 1)
    },

    customValidator () {
      if (!this.dataForm.imageList.length) {
        this.$opts.alert('必须上传检测文件！')
        return false
      }
      return true
    },

    beforeSubmit (params) {
      params.testsImage = params.imageList.join(',')
      return params
    }
  }
}
</script>
